import * as React from "react";
import "../../gatsby-browser.js";
import Navbar from "../component/Navbar/Navbar";
import Footer from "../component/Footer/Footer";
import { motion } from "framer-motion";
import Services from "../static/img/img/work.png";
// import { lan_data } from "../component/Local/local";
import { lan } from "../lan/service";
import Angular from "../static/img/webDevelopment/angular-icon.svg";
import Reactjs from "../static/img/webDevelopment/react-icon.svg";
import Vue from "../static/img/webDevelopment/vue-icon.svg";
import Typescript from "../static/img/webDevelopment/typescript-icon.svg";
import Html from "../static/img/webDevelopment/html-icon.svg";
import Flutter from "../static/img/webDevelopment/flutter-icon.svg";
import Php from "../static/img/webDevelopment/php-icon.svg";
import Node from "../static/img/webDevelopment/node-icon.svg";
import Python from "../static/img/webDevelopment/python-icon.svg";
import Django from "../static/img/webDevelopment/django-icon.svg";
import Flask from "../static/img/webDevelopment/flask-icon.svg";
import Laravel from "../static/img/webDevelopment/laravel-icon.svg";
import Illustration from "../static/img/webDevelopment/web-development-illustration.svg"
import MetaTags from "../component/MetaTags.jsx";

class WebDevelopment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loading: true,
      name: [],
      email: [],
      phone: [],
      message: [],
    };
  }

  getName = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  getEmail = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  getPhone = (e) => {
    this.setState({
      phone: e.target.value,
    });
  };

  getMessage = (e) => {
    this.setState({
      message: e.target.value,
    });
  };

  render() {
    const pageVariants = {
      initial: {
        opacity: 0,
      },
      in: {
        opacity: 1,
      },
      out: {
        opacity: 0,
      },
    };

    const { data, loading } = this.state;

    return (
      <>
        <Navbar />
        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={pageVariants}
        >
          <section id="mobile-tech-banner">
            <div className="banner-text">
             
                  <h1 className="highlighted-text">
                    Make with our cutting-edge web application development
                  </h1>
                 
                    <p>{lan.en["web"]["head"][0]}</p>
                
                </div>
                    <img src={Illustration} alt="" />
               
             
          </section>
         
            <div className="row about-extra">
              <div align={"center"}>
                <h2 className="highlighted-text">
                  {" "}
                  About <span className="light-text"> Web Tech </span>
                </h2>
              </div>
              <div className="wow fadeInUp ">
                <p className={"web_about"}>{lan.en["web"]["about"][0]}</p>
              </div>
            </div>
          
          <section id="services" className="advance">
            <header className="section-header">
              <h3 className="light-text"> End-to-End Web Development </h3>
            </header>
            <div className="service-about">
              <p>{lan.en["web"]["service"][0]}</p>
            </div>
            <br />
            <div className="row pointers-container">
              <div
                className="col-md-12  wow bounceInUp "
                data-wow-duration="1.4s"
              >
                <div className="row">
                  <div
                    className="col-md-6  wow bounceInUp "
                    data-wow-duration="1.4s"
                  >
                    <div className="box box-2 blank_service">
                      <p>
                        <div className={"row"}>
                          <div className={"col-sm-12"}>
                            <span id={"one_point"}> 1. </span>{" "}
                            {lan.en["web"]["service"][1]}
                          </div>
                        </div>
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-md-6  wow bounceInUp "
                    data-wow-duration="1.4s"
                  >
                    <div className="box box-2 blank_service">
                      <p>
                        <div className={"row"}>
                          <div className={"col-sm-12"}>
                            <span id={"two_point"}> 2. </span>{" "}
                            {lan.en["web"]["service"][2]}
                          </div>
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </section>

          {/* ========================================  type =========================================== */}

          <section id="services" className="processing">
              <div className={"row"}>
                <div align={"center"}>
                  <br /> <br />
                  <br />
                  <h2 className="light-text"> Technologies We Use </h2>
                </div>
                <h4 className="highlighted-text" align="center">
                  Front-End Technologies
                </h4>
                <br /> <br />
                <div className="tech-container">
                  <div className="box ">
                    <div align="center">
                      <img
                        src={Angular}
                        alt=""
                        className="img-fluid"
                        width="100"
                      />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">Angular</h4>
                    </div>
                  </div>
                  <div className="box ">
                    <div align="center">
                      <img
                        src={Reactjs}
                        alt=""
                        className="img-fluid"
                        width="100"
                      />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">ReactJs</h4>
                    </div>
                  </div>{" "}
                  <div className="box ">
                    <div align="center">
                      <img src={Vue} alt="" className="img-fluid" width="100" />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">Vue</h4>
                    </div>
                  </div>{" "}
                  <div className="box ">
                    <div align="center">
                      <img
                        src={Typescript}
                        alt=""
                        className="img-fluid"
                        width="100"
                      />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">Typescript</h4>
                    </div>
                  </div>{" "}
                  <div className="box ">
                    <div align="center">
                      <img
                        src={Html}
                        alt=""
                        className="img-fluid"
                        width="100"
                      />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">HTML5</h4>
                    </div>
                  </div>{" "}
                  <div className="box ">
                    <div align="center">
                      <img
                        src={Flutter}
                        alt=""
                        className="img-fluid"
                        width="100"
                      />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">Flutter Hybrid</h4>
                    </div>
                  </div>
                </div>
                <h4 className="highlighted-text" align="center">
                    Back-End Technologies
                  </h4>
                <div className="tech-container">
                  <div className="box ">
                    <div align="center">
                      <img
                        src={Php}
                        alt=""
                        className="img-fluid"
                        width="100"
                      />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">PHP</h4>
                    </div>
                  </div>
                  <div className="box ">
                    <div align="center">
                      <img
                        src={Node}
                        alt=""
                        className="img-fluid"
                        width="100"
                      />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">Node</h4>
                    </div>
                  </div> <div className="box ">
                    <div align="center">
                      <img
                        src={Python}
                        alt=""
                        className="img-fluid"
                        width="100"
                      />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">Python</h4>
                    </div>
                  </div> <div className="box ">
                    <div align="center">
                      <img
                        src={Django}
                        alt=""
                        className="img-fluid"
                        width="100"
                      />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">Django</h4>
                    </div>
                  </div> <div className="box ">
                    <div align="center">
                      <img
                        src={Flask}
                        alt=""
                        className="img-fluid"
                        width="100"
                      />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">Flask</h4>
                    </div>
                  </div> <div className="box ">
                    <div align="center">
                      <img
                        src={Laravel}
                        alt=""
                        className="img-fluid"
                        width="100"
                      />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">Laravel</h4>
                    </div>
                  </div>
              </div>
            </div>
          </section>

          <br />
          <br />

          <Footer />
        </motion.div>
        <MetaTags title={"Web Development | Koderbox"} />
      </>
    );
  }
}

export default WebDevelopment;
